@import "../../core/config/styles/theme.less";
@import "../../shared/main.less";

.map-layer-toogle-container {
  display: flex;
  margin-top: 6px;
}

.map-layer-farm-btn-sec {
  display: flex;
}

.map-field-btn {
  background-color: @color_green;
  color: @color_white;
  border-radius: 4px;
  &:active,
  &:hover,
  &:focus {
    background-color: @color_green;
    color: @color_white;
  }
}

.map-field-btn-outline {
  color: @color_green;
  border-radius: 4px;
  &:active,
  &:hover,
  &:focus {
    color: @color_green !important;
    background-color: @color_white !important;
  }
}

.mapview-header {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  .mapview-header-left {
    flex: 50%;
  }
  .mapview-header-right {
    flex: 50%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: normal;
    flex-wrap: wrap;
  }
  @media only screen {
    @media (max-width: 992px) {
      .mapview-header-right {
        margin-bottom: 10px;
      }
    }
    @media (max-width: 682px) {
      .mapview-header-right {
        justify-content: flex-start;
      }
    }
  }
  .map-layer-label {
    font-size: 14px;
    color: #232630;
  }
  .syt-antd-col-offset-1 {
    margin-left: 12%;
  }
}

.map-view-page {
  .map-container {
    position: relative;
    width: 100% !important;
    height: 70vh;
  }
  .map-container-full-screen {
    position: relative;
    width: 100% !important;
    height: 90vh !important;
    canvas {
      height: 100% !important;
      width: 100% !important;
      border-radius: 8px;
    }
  }
  .map-container canvas {
    width: 100% !important;
    border-radius: 8px;
    height: 100%;
  }

  @media screen and (max-width: 1200px) {
    .map-container {
      position: relative;
      width: 100% !important;
    }
    .map-container canvas {
      width: 100% !important;
    }
  }

  .mapboxgl-ctrl-btn-top-right {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: @color_white;
    border: 2px;
    border-radius: 8px;
    cursor: pointer;
    width: 40px;
    height: 40px;
  }

  .mapboxgl-ctrl-layer-sec {
    position: absolute;
    top: 58px;
    right: 10px;
    background-color: @color_white;
    border-radius: 8px;
    padding: 5px 12px;
    cursor: pointer;
    .syt-antd-space-item {
      width: 100%;
    }
  }

  .mapbox-layer-btn {
    background-color: #f3f4f6;
    border-radius: 8px;
    border: 0;
    text-align: left;
  }

  .mapboxgl-full-screen-btn-top-left {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: @color_white;
    border: 2px;
    border-radius: 8px;
    padding: 5px 5px;
    cursor: pointer;
    width: 39px;
    height: 39px;
    img {
      width: 78%;
      height: 78%;
    }
  }

  .mapboxgl-focus-farm-btn-bottom-right.farm-focus-btn {
    position: absolute;
    bottom: 140px;
    right: 10px;
    background-color: @color_white;
    border: 2px;
    border-radius: 8px;
    padding: 5px 5px;
    cursor: pointer;
    width: 40px;
    height: 40px;
  }

  .mapboxgl-focus-farm-btn-bottom-right.field-creation-cancel-btn {
    position: absolute;
    bottom: 390px;
    right: 10px;
    background-color: #14151c;
    border: 1px;
    border-radius: 8px;
    padding: 5px 5px;
    cursor: pointer;
    width: 40px;
    height: 40px;
  }

  .mapboxgl-focus-farm-btn-bottom-right.field-creation-confirm-btn {
    position: absolute;
    bottom: 340px;
    right: 10px;
    background-color: #14151c;
    border: 2px;
    border-radius: 8px;
    padding: 5px 5px;
    cursor: pointer;
    width: 40px;
    height: 40px;
  }

  .mapboxgl-focus-farm-btn-bottom-right.field-creation-undo-btn {
    position: absolute;
    bottom: 290px;
    right: 10px;
    background-color: #14151c;
    border: 2px;
    border-radius: 8px;
    padding: 5px 5px;
    cursor: pointer;
    width: 40px;
    height: 40px;
  }

  .mapboxgl-focus-farm-btn-bottom-right.field-creation-delete-btn {
    position: absolute;
    bottom: 240px;
    right: 10px;
    background-color: #14151c;
    border: 2px;
    border-radius: 8px;
    padding: 5px 5px;
    cursor: pointer;
    width: 40px;
    height: 40px;
  }

  .mapboxgl-focus-farm-btn-bottom-right.field-creation-mode-change-btn {
    position: absolute;
    bottom: 190px;
    right: 10px;
    background-color: #14151c;
    border: 2px;
    border-radius: 8px;
    padding: 5px 5px;
    cursor: pointer;
    width: 40px;
    height: 40px;
    .enable-field-create-mode-icon {
      width: 28px;
      height: 28px;
    }
  }

  .mapboxgl-ctrl-group {
    background: #ffffff;
    border-radius: 8px;
    .mapboxgl-ctrl-zoom-in {
      height: 35px;
    }
    .mapboxgl-ctrl-zoom-out {
      height: 35px;
    }
  }

  .mapboxgl-ctrl-group button {
    background-color: transparent;
    border: 2px;
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    height: 40px;
    outline: none;
    overflow: hidden;
    padding: 0px;
    width: 40px;
  }

  .all-hours-forecast {
    position: relative;
    width: 100%;
    padding: 35px 20px 35px 20px;
    .forecast-carousel-btn {
      background-color: @color_white !important;
      width: 35px;
      height: 35px;
      border-radius: 20px;
      border: 0 !important;
    }
    .forecast-carousel-sec {
      width: 94%;
      position: relative;
      margin: 0 auto;
      z-index: 1;
    }
    @media only screen {
      @media (max-width: 440px) {
        .forecast-carousel-sec {
          width: 80%;
        }
      }
      @media (max-width: 876px) {
        .forecast-carousel-sec {
          width: 90%;
        }
      }
      @media (max-width: 663px) {
        .forecast-carousel-sec {
          width: 86%;
        }
      }
    }
    .forecast-left-btn-sec,
    .forecast-right-btn-sec {
      position: absolute;
      top: 80px;
      z-index: 2;
    }
    .forecast-left-btn-sec {
      left: 0px;
    }
    .forecast-right-btn-sec {
      right: 0px;
    }
    .each-hour-forecast-sec {
      border-radius: 8px;
      margin: 0px 3px;
      background-color: @--neutral-neutral-00;
      padding: 0px 5px 0px 5px !important;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: 120px;
      .each-hour-time-label {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
      }
      .each-hour-temp-rain,
      .each-hour-rain,
      .each-hour-temp,
      .each-hour-wind {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        img {
          margin-top: 2px;
          width: 13px;
          height: 14px;
        }
        p {
          margin-left: 5px;
        }
      }
      p {
        font-size: 10px;
        font-family: @font_family_noto;
        color: @color_black;
      }
    }
    .each-hour-forecast-sec::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 8px;
      right: 8px;
      height: 4px;
      background-color: @--green-green-75;
      border-radius: 10px;
    }
    .each-hour-forecast-pictoCode-icon {
      width: 28px;
      height: 28px;
    }
  }
}

.meteoblue-map-iframe {
  height: 100% !important;
  border: none;
  border-radius: 8px;
}

.field-report-title {
  margin-top: 20px;
  width: auto;
  height: 33px;
  top: 956px;
  left: 325px;
  font-family: @font_family_noto;
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
  color: #27262e;
  margin-bottom: 10px;
}

.field-report-desc {
  font-family: @font_family_noto;
  font-size: 15px;
  line-height: 23px;
  font-weight: 400;
  color: #696F88;
  margin-bottom: 20px;
}

@import "../../shared/main.less";

.farm-image {
  left: 0px;
  top: 0px;
  width: 50%;
  height: 100vh;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  position: relative;
}
@media (max-width: 768px) {
  .farm-image {
    width: 0px !important;
  }
  .login-sec {
    width: 100% !important;
  }
}

.login-sec {
  background: #ffffff;
  width: 50%;
}

.login-container {
  padding: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  .lng-options {
    display: flex;
    align-items: center;
    align-self: flex-end;
    .lng-selection-dropdown {
      align-self: flex-end;
      border: none;
      & li.syt-antd-menu-submenu {
        &.syt-antd-menu-submenu-selected::after {
          border: none;
        }
      }
    }
  }
}

.syn-field-logo {
  width: 188px;
  height: 70px;
  margin: 20px 0px 40px 0px;
}

.sign-in-desc {
  font-family: @font_family_noto;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.0075em;
  text-align: center;
  color: #14151c;
  font-style: normal;
}

.sign-in-sub-desc {
  color: #14151c;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 20px;
  text-align: center;
  font-family: @font_family_noto;
}

.login-form {
  width: 60%;
  display: flex;
  flex-direction: column;
}

.select-label {
  color: #000000;
  font-family: @font_family_noto;
  font-size: 14px;
  margin-bottom: 0px;
}

.login-btn {
  background-color: #14803c;
  font-family: @font_family_noto;
  font-weight: 500;
  color: #ffffff;
  font-size: 12px;
  height: 40px;
  margin: 10px;
}

.login-btn-disabled {
  background-color: #f3f4f6 !important;
  color: #c2c7d0 !important;
  border: 1px solid #dfe2e7 !important;
}

.login-page-signup {
  font-size: 13px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-page-signup-link {
  color: #14803c;
}

@media only screen {
  @media (max-width: 992px) {
    .login-form {
      width: 90% !important;
    }
  }
  @media (max-width: 768px) {
    .login-form {
      width: 70% !important;
    }
  }
  @media (min-width: 480px) {
    .user-not-found-modal {
      max-width: 470px !important;
    }
  }
}

:where(.css-dev-only-do-not-override-zl9ks2).ant-input-outlined:hover {
  border-color: #d9d9d9;
  background-color: #f3f4f6;
}

:where(.css-dev-only-do-not-override-zl9ks2).ant-input-outlined:focus,
:where(.css-dev-only-do-not-override-zl9ks2).ant-input-outlined:focus-within {
  border-color: #d9d9d9;
  background-color: #f3f4f6;
}

.user-not-found-modal {
  .syt-antd-modal-content {
    .syt-antd-modal-header {
      border: none !important;
      padding: 24px 24px 0px !important;
      .new-error-modal-container {
        display: flex;
        gap: 12px;
      }
      .error-modal-header-message {
        color: #14151c;
        font-size: 16px;
        font-weight: 600;
      }
    }
    .syt-antd-modal-body {
      padding: 20px 20px 20px 60px;
      .error-modal-user-details {
        border: 1px solid #c2c7d0;
        border-radius: 8px;
        box-sizing: border-box;
        margin: 8px auto 0;
        padding: 8px;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
      }
    }
    .syt-antd-modal-footer {
      border: none !important;
      .syt-antd-btn-primary {
        border-color: #14803c !important;
        background: #14803c !important;
      }
    }
  }
}
.myfield_text {
  text-align: left;
}
.myfield_warnning_popup {
  display: inline-flex;
}
.myfield_warnning_icon {
  width: 24px;
  height: 24px;
  padding: 2px 0px 0px 0px;
  margin-right: 8px;
}

.forgot-password {
  font-family: @font_family_noto;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.0075em;
  text-align: right;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  display: flex;
  justify-content: end;  
  color: #14803C;
}
.forgot-password:hover {
  color: #14803C;
}


@import "../../shared/main.less";

@media only screen {
  @media (max-width: 768px) {
    .forgot-password-image {
      width: 0px !important;
    }
  }
  @media (max-width: 992px) {
    .forgot-password-form {
      width: 90% !important;
    }
  }
  @media (max-width: 768px) {
    .forgot-password-form {
      width: 70% !important;
    }
    .forgot-password-sec {
      width: 100% !important;
    }
  }
}
.forgot-password-field-logo {
  width: 150px;
  height: 65px;
  margin-bottom: 10px;
  margin-top: 5px;
}
.forgot-password-sec {
  background: #ffffff;
  width: 50%;
}
.forgot-password-container {
  padding: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  .lng-options {
    display: flex;
    align-items: center;
    align-self: flex-end;
    .lng-selection-dropdown {
      align-self: flex-end;
      border: none;
      & li.syt-antd-menu-submenu {
        &.syt-antd-menu-submenu-selected::after {
          border: none;
        }
      }
    }
  }
}
.forgot-password-image-style {
  width: 378px;
  height: 163px;
  gap: 0px;
  border: 0.83px 0px 0px 0px;
}
.forgot-password-form {
  width: 60%;
  display: flex;
  flex-direction: column;
}
.forgot-password-image {
  left: 0px;
  top: 0px;
  width: 50%;
  height: 100vh;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  position: relative;
}
.forgot-password-msg {
  width: 316px;
  text-align: center;
}

.forgot-password-success-msg {
  font-family: @font_family_noto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.0075em;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.forgot-password-footer {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  margin-right: 1px;
  margin-left: 25px;

  .lng-selection-dropdown {
    align-self: flex-end;
    border: none;
    & li.syt-antd-menu-submenu {
      &.syt-antd-menu-submenu-selected::after {
        border: none;
      }
    }
  }
  .syt-antd-menu-horizontal {
    line-height: 0px;
  }
}
.forgot-password-button {
  font-family: @font_family_noto;
  font-weight: 500;
  color: @color_white;
  font-size: 12px;
  height: 40px;
  margin: 10px;
}
.forgot-password-terms-btn {
  color: @color_green;
  align-self: flex-end;
}
.forgot-password-label {
  color: #14151c;
  font-family: @font_family_noto;
  font-size: 10px;
  margin-bottom: 0px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.forgot-password-desc {
  font-family: @font_family_noto;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -0.0075em;
  text-align: center;
  color: #14151c;
  font-style: normal;
  margin-bottom: 10px;
  margin-top: 20px
}

.forgot-new-password-buttons {
  display: flex;
  justify-content: space-evenly;
  margin-top: 15px;
  .forgot-buttons-wdith {
    width: 50%;
    margin: 5px;
  }
}
.forgot-newpassword-success-container {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: @color_white;
  overflow: scroll;
  height: 100vh;
}
.forgot-newpassword-success-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  text-align: center;
  max-width: 450px;
  height: 95%;
  .lng-selection-dropdown {
    align-self: flex-end;
    border: none;
    & li.syt-antd-menu-submenu {
      &.syt-antd-menu-submenu-selected::after {
        border: none;
      }
    }
  }
  .syt-antd-menu-horizontal {
    line-height: 21px;
  }
}


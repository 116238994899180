@import "../../shared/main.less";

.banner-title {
  font-family: @font_family_noto;
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
  color: @color_black;
  margin-bottom: 20px;
}
.tiles-title-text {
  font-family: @font_family_noto;
  font-size: 9px;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: -0.0075em;
  text-align: left;
  width: 54.67px;
  height: 13.96px;
  color: @color_black;
  white-space: nowrap;
  margin-right: 5px;
}
.tiles-title-icon {
  width: 59.84px;
  height: 23.83px;
}
.tiles-syngenta-layout {
  width: 160px;
  height: 173px;
  border-radius: 11.58px;
  border: 0.96px solid @color_white;
  background: linear-gradient(0deg, @color_white, @color_white);
  margin-right: 2px;
  margin-bottom: 5px;
  padding: 15px;
  text-align: center;
  cursor: pointer;
}
.tiles-product-description {
  font-family: @font_family_noto;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: -0.0075em;
  text-align: center;
  color: @color_black;
}

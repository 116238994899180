.legal-docs-container {
  height: 100vh;
}

.legal-docs-sidebar {
  position: relative;
  border-right: 1px solid #dfe2e7;
}

.close-icon-wrapper {
  display: none;
  position: absolute;
  top: 25px;
  right: 25px;
  border: none;
  outline: none;
  background: white;
}

.legal-docs__sidebar {
  padding-top: 20px;
}

.legal-docs__logo {
  height: 38px;
  padding-left: 35px;
}

.legal-documents-title {
  font-family: "Noto Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 20px;
  letter-spacing: 0.4px;
  color: #232630;
  margin-top: 22px;
  padding-left: 35px;
}

.legal-documents-title.mobile {
  display: none;
}

.legal-documents-search.mobile {
  display: none;
}

.legal-docs_sidebar_menu {
  cursor: pointer;
  height: 40px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  background-color: white;
  border: none;
  outline: none;
  padding-left: 35px;
  width: 100%;
}

.legal-docs_sidebar_menu .memu-title {
  letter-spacing: 0.4px;
  margin-bottom: 0;
  font-family: "Noto Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #14151c;
}

.legal-docs_sidebar_menu.doc-selected {
  background-color: #eaf6ff;
  color: #0071cd;
  position: relative;
}

.legal-docs_sidebar_menu.doc-selected .memu-title {
  color: #0071cd;
}

.legal-docs_sidebar_menu.doc-selected::before {
  content: "";
  display: block;
  background-color: #0071cd;
  height: 30px;
  width: 4px;
  border-radius: 0 4px 4px 0;
  position: absolute;
  left: 0;
}

.select-country {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  margin-right: 24px;
  padding-left: 38px;
}

.select-country > span > svg {
  fill: #696f88;
}

.syt-antd-tooltip-inner {
  display: block;
}

.search-country-wrapper {
  position: relative;
  margin-inline: 35px 24px;
  margin-top: 5px;
}

.country-search-icon {
  position: absolute;
  right: 10px;
  top: 9px;
}

.country-search-icon > svg {
  fill: #c1c5c8;
}

.pdf-viewer-container {
  background-color: white;
  max-height: 100vh;
  overflow: auto;
  position: relative;
}

.legal-docs-menuIcon {
  display: none;
  max-width: 25px;
  max-height: 25px;
}
.pdf-viewer-box {
  border: 1px solid #dfe2e7;
  border-radius: 5px;
  margin: 84px 135px;
  background: #f3f4f6;
}

.pdf-viewer-box .react-pdf__Document {
  max-width: min-content;
  margin: auto;
  overflow: auto;
}

.pdf-viewer-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 30px 10px 30px;
  border-bottom: 1px solid #dfe2e7;
  background: white;
  border-radius: 5px 5px 0 0;
}

.pdf-viewer-title {
  display: block;
  font-size: 14px;
  color: #363948;
  font-weight: 600;
  font-family: "Noto Sans", sans-serif;
  line-height: 1.5715;
  letter-spacing: 0.4px;
}

.pdf-viewer-name {
  display: block;
  font-size: 14px;
  color: #707374;
  font-weight: 400;
  font-family: "Noto Sans", sans-serif;
  line-height: 1.5715;
  letter-spacing: 0.4px;
}

.pdf-download-btn > span > svg {
  fill: #707374;
}

.legal-docs-header-mobile {
  display: flex;
  align-items: center;
}

.language-selection-menu.sidebar {
  position: absolute;
  bottom: 30px;
  display: flex;
  justify-content: flex-end;

  border: none;
  & li.syt-antd-menu-submenu {
    min-width: 222px;
    &.syt-antd-menu-submenu-selected::after {
      border: none;
    }
  }
}

.language-selection-menu .custom-language-menu-title.sidebar {
  justify-content: flex-end;
}

.custom-language-menu-title.sidebar > .arrow-icon {
  transform: rotate(90deg);
  margin-left: 16px;
}
.legal-docs-no-content, .legal-docs-loader-wrapper {
  min-height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  .mf-loader {
    left: unset;
  }
}

.legal-docs-no-content > p{
  font-size: 16px;
  color: #333;
}

@media only screen {
  @media (max-width: 1200px) {
    .pdf-viewer-box {
      margin: 84px 100px;
    }
  }
  @media (max-width: 992px) {
    .pdf-viewer-box {
      margin: 84px 20px;
    }

    .legal-docs-menuIcon {
      display: block;
    }

    .close-icon-wrapper {
      display: block;
    }
  }
  @media (max-width: 425px) {
    .pdf-viewer-box {
      margin: 43px 0;
    }
    .pdf-viewer-header {
      padding: 10px 15px 10px 15px;
    }
    .pdf-viewer-title,
    .pdf-viewer-name {
      font-size: 12px;
    }
    .legal-documents-title.sidebar {
      display: none;
    }
    .legal-documents-title.mobile {
      display: block;
    }
    .legal-docs_sidebar_menu_wrapper {
      margin-top: 30px;
    }
    .legal-documents-search.sidebar {
      display: none;
    }
    .legal-documents-search.mobile {
      display: block;
      margin-inline: 16px;
    }
    .legal-documents-title {
      font-size: 16px;
      margin-top: 19px;
    }
    .select-country {
      margin-top: 16px;
      margin-right: unset;
      padding-left: unset;
    }
    .search-country-wrapper {
      margin-inline: unset;
    }
  }
}

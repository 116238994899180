@import "../../shared/main.less";

.farm-image {
  left: 0px;
  top: 0px;
  width: 50%;
  height: 100vh;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  position: relative;
}

.signup-page {
  display: flex;
  height: 100vh;
}

@media (max-width: 768px) {
  .farm-image {
    width: 0px !important;
  }
  .sign-up-sec {
    width: 100% !important;
  }
}

.sign-up-sec {
  background: #ffffff;
  width: 50%;
  height: 100%;
  overflow-y: scroll;
  .syt-antd-select-single:not(.syt-antd-select-customize-input)
    .syt-antd-select-selector {
    background-color: #f3f4f6 !important;
    border-radius: 2px;
  }
}

.sign-up-container {
  padding: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  .lng-options {
    display: flex;
    align-items: center;
    align-self: flex-end;
    .lng-selection-dropdown {
      align-self: flex-end;
      border: none;
      & li.syt-antd-menu-submenu {
        &.syt-antd-menu-submenu-selected::after {
          border: none;
        }
      }
    }
  }
}

.syn-field-logo {
  width: 188px;
  height: 70px;
  margin: 20px 0px 30px 0px;
}

.sign-up-desc {
  font-family: @font_family_noto;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.0075em;
  text-align: center;
  color: #14151c;
  font-style: normal;
}

.sign-up-form {
  width: 60%;
  display: flex;
  flex-direction: column;
}

@media only screen {
  @media (max-width: 992px) {
    .sign-up-form {
      width: 90% !important;
    }
  }
  @media (max-width: 768px) {
    .sign-up-form {
      width: 70% !important;
    }
  }
}

.select-label {
  color: #000000;
  font-family: @font_family_noto;
  font-size: 14px;
  margin-bottom: 0px;
}

.sign-up-btn {
  background-color: #14803c;
  font-family: @font_family_noto;
  font-weight: 500;
  color: #ffffff;
  font-size: 12px;
  margin: 10px;
}

.sign-up-btn-disabled {
  background-color: #f3f4f6 !important;
  color: #c2c7d0 !important;
  border: 1px solid #dfe2e7 !important;
}

.sign-up-first-last-name {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .first-name-sec,
  .second-name-sec {
    flex: 50%;
  }
  @media (max-width: 576px) {
    .first-name-sec,
    .second-name-sec {
      flex: 100%;
    }
  }
  @media (min-width: 993px) and (max-width: 1200px) {
    .first-name-sec,
    .second-name-sec {
      flex: 100%;
    }
  }
}

.mobile-number-label-sec,
.loc-res-label-sec {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  .mobile-number-label-left-sec,
  .loc-res-label-left-sec {
    flex: 50%;
  }
}

.sign-up-sub-desc {
  color: #4d5165;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.password-input {
  margin: 2px 0 0px;
}

:where(.css-dev-only-do-not-override-zl9ks2).ant-input-outlined:hover {
  border-color: #d9d9d9;
  background-color: #f3f4f6;
}

:where(.css-dev-only-do-not-override-zl9ks2).ant-input-outlined:focus,
:where(.css-dev-only-do-not-override-zl9ks2).ant-input-outlined:focus-within {
  border-color: #d9d9d9;
  background-color: #f3f4f6;
}

.password-label-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.select-job-title-container {
  background: #fff;
  border-radius: 4px;
  color: #14151c;
  height: 40px;
  margin-bottom: 12px;
  width: 100%;
}

.sign-up-btn.defaultButton:active, .sign-up-btn.defaultButton:focus, .sign-up-btn.defaultButton:hover {
  color: #ffffff;
  background-color: #14803c;
  border-color: #ffffff;
  box-shadow: 0px 0px 0px 3px rgba(255, 255, 255, 0.25);
}

.sign-up-btn {
  .syt-antd-btn-loading-icon {
    .anticon {
      svg {
        width: 18px;
        height: 18px;
      }
    }
  }
}

.react-tel-input {
  .form-control {
    width: 100% !important;
    background-color: #f3f4f6 !important;
    border-radius: 2px;
    height: 40px;
  }
  margin-bottom: 15px !important;
}

.privacy-notice-sec {
  font-size: 13px;
}

.password-progress-bar {
  margin-bottom: 20px !important;
}

.privacy-button-link {
  font: inherit;
  text-decoration: underline !important;
  cursor: pointer;
  color: #31b4f2;
}

.privacy-button-link:hover {
  color: #31b4f2 !important;
}

.privacy-button-link:active {
  color: #31b4f2 !important;
}

.option-flag-image {
  height: 1em;
  width: 1em;
}

.country-options {
  display: flex;
  align-items: center;
}

.user-exist-modal {
  .syt-antd-modal-content {
    .syt-antd-modal-header {
      border: none !important;
      padding: 24px 24px 0px !important;
      .new-error-modal-container {
        display: flex;
        gap: 18px;
      }
      .error-modal-header-message {
        color: #14151c;
        font-size: 16px;
        font-weight: 600;
      }
    }
    .syt-antd-modal-footer {
      border: none !important;
      .syt-antd-btn-primary {
        border-color: #14803c !important;
        background: #14803c !important;
      }
    }
  }
}

@import "../../shared/main.less";

@media only screen {
  @media (max-width: 768px) {
    .new-password-image {
      width: 0px !important;
    }
  }
  @media (max-width: 992px) {
    .new-password-form {
      width: 90% !important;
    }
  }
  @media (max-width: 768px) {
    .new-password-form {
      width: 70% !important;
    }
    .new-password-sec {
      width: 100% !important;
    }
  }
}
.change-passwerd-error {
  border: 1px solid @color_red !important;
}
.new-password-field-logo {
  width: 150px;
  height: 65px;
  margin-bottom: 10px;
  margin-top: 5px;
}
.new-password-sec {
  background: #ffffff;
  width: 50%;
}
.new-password-container {
  padding: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
}

.new-password-form {
  width: 60%;
  display: flex;
  flex-direction: column;
}
.new-password-image {
  left: 0px;
  top: 0px;
  width: 50%;
  height: 100vh;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  position: relative;
}
.new-password-msg {
  text-align: center;
}
.new-password-label {
  color: #14151c;
  font-family: @font_family_noto;
  font-size: 10px;
  margin-bottom: 0px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.new-password-desc {
  font-family: @font_family_noto;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -0.0075em;
  text-align: center;
  color: #14151c;
  font-style: normal;
  margin-bottom: 10px;
}

.new-password-buttons {
  display: flex;
  justify-content: space-evenly;
  margin-top: 15px;
  .new-buttons-wdith {
    width: 50%;
    margin: 5px;
  }
}
